import React from 'react';
import VideoSection from 'components/common/VideoSection';

const video = {
  filename: 'videos/thumbnails/comment-consulter-mia-co.png',
  alt: '',
  video: 'https://www.youtube.com/embed/FlgazPKd1BI',
};

const VideoEpanouissement = () => <VideoSection {...video} />;

export default VideoEpanouissement;
