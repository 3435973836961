import React from 'react';
import Man1 from 'images/men/bulle1.svg';
import Man2 from 'images/men/bulle2.svg';
import Man3 from 'images/men/bulle3.svg';

export const testimonies = [
  {
    testimony: 'Je me suis toujours intéressée à la sexualité, je pense connaitre mon corps, être au courant des pratiques : je me sens libre et libérée sur le sujet. Seulement, je voulais aller plus loin que les généralités et être en complète connexion avec moi-même. J’ai pris une séance pour poser toutes les questions que j’avais en tête et découvrir à la fois, une nouvelle manière de percevoir ma sexualité. C’est validé.',
    patient: 'Maud',
    city: '',
    age: '26 ans',
    Icon: Man1,
    backgroundColorClass: 'lc-light-blue',
    flower: true,
    target: false,
  },
  {
    testimony:
      'Cela fait 25 ans que je vis avec mon mari et nous avons toujours pris soin de notre sexualité malgré les aléas de la vie. J’avais envie de savoir si nous pouvions aller plus loin, découvrir de nouvelles pratiques, de nouveaux jeux, de nouvelles possibilités pour s’amuser ensemble et ne pas laisser notre flamme diminuer… Ca a été un plaisir d’explorer tout cela avec la sexologue que j’ai consultée, on s’amuse bien plus depuis…',
    patient: 'Jenny',
    city: '',
    age: '51 ans',
    Icon: Man3,
    backgroundColorClass: 'lc-light-yellow',
    flower: false,
    target: true,
  },
  {
    testimony:
      'J’ai décidé de consulter une sexologue pour faire le point sur ma sexualité. J’ai fait mon coming-out depuis trois mois et cela a été plutôt bien accueilli autour de moi. Je me posais beaucoup de question, qu’elles soient “pratiques”, relationnelles ou tout simplement, sur le fait de m’assumer pleinement. Merci encore à la sexologue qui m’a suivi, je me sens droite dans mes bottes comme jamais je ne l’ai été.',
    patient: 'Lou-Anne',
    city: '',
    age: '23 ans',
    Icon: Man2,
    backgroundColorClass: 'lc-light-cyan',
    flower: true,
    target: false,
  },
  {
    testimony:
      'J’avais envie d’aller plus loin, de me réinventer sexuellement et de ré-enchanter si je puis dire, mes rapports. J’ai pu discuter avec la sexologue de nouvelles pratiques comme le slow sex ou le tantrisme. J’ai toute la théorie sous le bras, j’ai hâte de m’amuser avec tout ça et de refaire le point avec elle.',
    patient: 'Marie',
    city: '',
    age: '30 ans',
    Icon: Man2,
    backgroundColorClass: 'lc-light-cyan',
    flower: false,
    target: true,
  },
];

export const sections = [
  {
    title:
      'Qu’est-ce que la santé sexuelle ?',
    body: (
      <div>
        <p>
          Santé générale et santé sexuelle sont intimement liées. La santé sexuelle fait référence à
          un état de bien-être émotionnel, physique et mental, social, en relation avec la
          sexualité, et pas uniquement en lien avec l’absence de maladies (IST), les troubles
          sexuels ou les dysfonctionnements.
        </p>
        <p>
          Donc, la santé sexuelle désigne une sexualité vécue dans une approche positive et
          respectueuse à travers les relations sexuelles, et la possibilité d’avoir des expériences
          sexuelles satisfaisantes et sûres, sans discrimination, contrainte ou violence.
        </p>
        <p>
          Il existe donc des droits sexuels pour toutes les personnes, qui ont pour fonction de
          leur permettre respect et protection, afin d’atteindre et maintenir une bonne santé
          sexuelle. Parmi ces droits, nous pouvons citer :
          <p className="ml-4 mt-3">
            • L’égalité, la liberté et le libre choix, la lutte contre les discriminations (âge,
            sexe, genre, état mental et physique, etc.), l’absence de contrainte, la responsabilité,
            le droit à la contraception, le droit et l’accès à des informations et connaissances en
            matière de sexualité, etc.
            <br />
            • Le droit de se développer sexuellement : développement psycho-affectif, mais aussi
            corporel, les phases de croissance, accepter les différences entre les sexes, le genre,
            etc.
            <br />
            • Permettre le cadre d’une sexualité reproductive et d’un planning familial : grossesse,
            post-partum, prise en charge des grossesses non désirées, contraception (accès à des
            structures de soutien, des différentes méthodes contraceptives, le droit à l’IVG,
            etc.) ;
            <br />
            • Un accès au dépistage et aux soins des IST, ainsi qu’une information adéquate sur les
            risques de transmission, la prévention, la prise en charge et les traitements ; vivre
            avec le VIH, connaissance des vaccins, etc.
            <br />
            • Les droits qui concernent la protection face aux violences et abus sexuels ;
            <br />
            • L’identité de genre et l’orientation sexuelle ;
            <br />
            • La sexualité et l’âge ;
            <br />
            • L’accès équitable aux prestations ;
            <br />
            • La reconnaissance des handicaps physiques et mentaux ;
            <br />
            • Les maladies chroniques ;
            <br />
            • L’impact des réseaux sociaux ou des médias sur la sexualité ;
            <br />
            • Les comportements sexuels et la pornographie ;
            <br />
            • La consommation de drogues, etc.
          </p>
        </p>
        <p>
          De nombreux autres droits – que nous ne pouvons tous citer ici – permettent aussi de
          prendre en compte la définition de la santé sexuelle et chaque sexualité dans sa
          pluralité.
        </p>
      </div>
    ),
  },
  {
    title:
      'Comment définir la sexualité ?',
    body: (
      <div>
        <p>
          La santé sexuelle est intimement liée à la manière dont on envisage la sexualité, et aux
          questions de société. On peut cependant dire qu’elle [la sexualité] englobe des aspects
          fondamentaux de l’être humain durant toute sa vie.
        </p>
        <p>
          La sexualité regroupe donc le sexe, l’identité et le rôle des êtres humains dans la
          procréation, l’orientation sexuelle, l’intimité ou encore l’érotisme. On peut l’exprimer à
          travers des fantasmes, des pensées, le désir sexuel, des attitudes, des comportements, des
          valeurs, des relations, des pratiques ou des rôles, etc.
        </p>
        <p>
          <p>
            Elle est donc un aspect central de l’être humain, fondamental, et doit être reconnue
            comme un aspect positif de la vie, afin d’être vécue sans contrainte, violence ou
            discrimination. Une bonne santé sexuelle implique donc que tout le monde puisse
            notamment :
          </p>
          <p className="ml-4 mt-3">
            • Effecteur ses propres choix – autant dans la vie sexuelle que reproductive ;
            <br />
            • Et exprimer aussi son orientation sexuelle et son identité de genre, sans craindre
            des violences ou des discriminations.
          </p>
        </p>
        <p>
          Il est donc indispensable de fonder la sexualité et la santé sexuelle sur des droits
          sexuels.
        </p>
      </div>
    ),
  },
  {
    title:
      'Pourquoi l’éducation sexuelle est-elle importante ?',
    body: (
      <div>
        <p>
          L’éducation de la sexualité est indispensable pour permettre aux individus de prendre des
          décisions informées, rationnelles, sans se mettre en danger. En effet, nous savons depuis
          de nombreuses années que le niveau d’éducation a un impact évident sur la santé en
          générale.
        </p>
        <p>
          Ce facteur est aussi valable dans le champ de la santé sexuelle.
        </p>
        <p>
          Donc, à chaque étape de la vie, la population doit avoir accès à un bon niveau d’éducation
          concernant les questions de santé et les droits sexuels. Ce niveau d’éducation a pour
          objectif de donner à toutes et tous des connaissances et des compétences psychosociales
          nécessaires aux prises de décisions responsables et informées.
        </p>
        <p>
          La sexualité à travers cette éducation doit être abordée de manière positive et
          bienveillante, en montrant ses bienfaits, afin de contribuer à combattre les préjugés,
          les stigmatisations, les stéréotypes, les discriminations fondées sur le sexe,
          l’orientation sexuelle, le genre, l’âge, l’état de santé, les pratiques sexuelles ou la
          situation sociale et économique de chacun.
        </p>
        <p>
          La scolarité doit par ailleurs garantir cette éducation pour les enfants et les
          adolescents, sous la forme de programmes d’éducation sexuelle, qui peuvent venir compléter
          l’éducation parentale. C’est pendant cette période scolaire que peut se constituer le
          socle nécessaire à la compréhension des actions de prévention ultérieures : grossesses
          non désirées, VIH et IST, violences, abus, homophobie, etc.
        </p>
        <p>
          Toutes ces actions, et cette éducation sexuelle, entrent directement en jeu lors des
          situations qui pourraient être mal vécues ou lors de comportements à risques.
        </p>
      </div>
    ),
  },
  {
    title:
      'Comment téléconsulter une sexologue sur Mia ?',
    body: (
      <div>
        <p>
          Avant la téléconsultation, il est d’abord nécessaire de remplir un questionnaire médical
          précis, qui permettra au sexologue de connaître d’éventuels antécédents médicaux. Ensuite,
          la patiente peut choisir le créneau horaire qui lui convient le mieux, ainsi que le jour
          et la date.
        </p>
        <p>
          <p>
            3 modes de téléconsultation s’offrent alors :
          </p>
          <p className="ml-4 mt-3">
            • Par
            {' '}
            <b>vidéo ;</b>
            <br />
            •
            {' '}
            <b>Messages sécurisés ;</b>
            <br />
            • Ou par
            {' '}
            <b>téléphone.</b>
          </p>
        </p>
        <p>
          Une fois le RDV confirmé et les informations personnelles remplies, le prix de la
          téléconsultation doit être préréglé sur la plateforme.
        </p>
        <p>
          Lorsque la consultation est terminée, le dossier patient est accessible à tout moment dans
          l’espace personnel, ainsi que le compte-rendu de la consultation et les recommandations
          d’accompagnement.
        </p>
      </div>
    ),
  },
  {
    title:
      'Quels sont les avantages de Mia.co ?',
    body: (
      <div>
        <p>
          • Une équipe de praticiennes (sexologues, sages-femmes, thérapeutes de couple)
          spécialistes de la sexualité féminine et spécialement formées à la pratique de la
          téléconsultation ;
        </p>
        <p>
          • Un comité scientifique composé de médecins sexologues, de professeures d’université en
          sexologie et de membres dirigeants d’associations de sexologues ;
        </p>
        <p>
          • Une consultation de 30 min à 45 euros (les prix en cabinet varient de 80 à 100 euros) ;
        </p>
        <p>
          • La livraison de produits d’accompagnement en 24 ou 48H.
        </p>
      </div>
    ),
  },
];
