import React, { useState } from 'react';
import CharlesMeta from 'components/common/meta/CharlesMeta';

import Container from 'react-bootstrap/Container';
import { Layout } from 'components/common';
import TextSectionList from 'components/common/TextSectionList';
import Section from 'components/common/Section';
import TestimoniesSection from 'components/common/TestimoniesSection';
import Image from 'components/common/utils/Image';

import StyledLink from 'components/common/utils/StyledLink';

import VideoEpanouissement from '../common/VideoEpanouissement';

import { testimonies, sections } from './data';

import './styles.sass';

import HowItWorksSection from '../HowItWorksSection';
import Collapse from 'react-bootstrap/Collapse';
import Hush from 'images/icons/hush.svg';
import Medal from 'images/icons/medal.svg';
import PharmaCross from 'images/icons/pharma-cross-with-heart.svg';
import TherapistCarousel from '../common/TherapistsCarousel';

const IndexPage = () => {
  const [open, setOpen] = useState(false);
  return (
    <Layout
      footerCtaPath="/consulter-baisse-de-libido/"
      showLinksInFooter={false}
      backgroundBeforeFooter="background-color-pale-yellow"
    >
      <CharlesMeta
        data={{
          title: 'Vous souhaitez vous épanouir sexuellement ? Consultez en ligne une thérapeute ',
          description:
            "Enrichir sa sexualité, prendre plus de plaisir ou découvrir son corps ? Consultez une thérapeute en ligne par vidéo, téléphone ou messagerie 7 jours sur 7.",
        }}
      />
      <div className="text-left landing-page">
        <Section className="text-white d-flex flex-column background-color-pale-yellow home-top-section patho-top-section epanouissement-top-section m-lg-0">
          <h1 className="h1 text-center main-title-patho align-self-center d-lg-none mb-1">
            À la recherche de votre bien-être sexuel&nbsp;? Et si vous en parliez à une
            sexologue&nbsp;?
          </h1>
          <div className="desktop-home-top-div desktop-patho-top-div d-none d-lg-flex">
            <div className="home-top-left">
              <div>
                <h1 className="h1-patho-top-left">
                  À la recherche de votre bien-être sexuel&nbsp;?
                  <br />
                  Et si vous en parliez à une sexologue&nbsp;?
                </h1>
                <div className="d-flex cta-div">
                  <StyledLink
                    variant="orange"
                    path="/consulter-epanouissement/"
                    className=""
                    margin
                  >
                    CONSULTER
                  </StyledLink>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column desktop-videoPL justify-content-lg-center align-items-lg-end">
              <VideoEpanouissement className="" />
              <span className="video-patho-text">
                Je suis Camille Bataillon, la sexologue qui a créé ce protocole de
                téléconsultation.
              </span>
            </div>
          </div>
          <div className="info-div info-div-patho">
            <div className="d-flex justify-content-center d-lg-none">
              <div className="d-flex cta-div">
                <StyledLink
                  variant="orange"
                  path="/consulter-epanouissement/"
                  className=""
                  margin
                >
                  CONSULTER
                </StyledLink>
              </div>
            </div>
            <div className="info-list info-list-patho">
              <div className="text-left">
                <div className="img-wrapper"><Hush /></div>
                <span className="hush-span">CONFIDENTIEL & SÉCURISÉ</span>
              </div>
              <div>
                <div className="img-wrapper"><Medal /></div>
                <span className="medal-span">SEXOLOGUES DIPLÔMÉES</span>
              </div>
              <div>
                <div className="img-wrapper"><PharmaCross /></div>
                <span className="pharmacross-span">SUIVIS & ASSISTANCE ILLIMITÉS</span>
              </div>
            </div>
          </div>
          <Container className="d-flex flex-column align-items-center video-patho-container d-lg-none">
            <VideoEpanouissement />
            <span className="video-patho-text">
              Je suis Camille Bataillon, la sexologue qui a créé ce protocole de téléconsultation.
            </span>
          </Container>
        </Section>
        <HowItWorksSection />
        <Section className="d-flex flex-column flex-lg-row patho-explanation-section justify-content-lg-center align-items-lg-center">
          <div className="d-none d-lg-flex patho-explanation-left">
            <Image filename="blooming.png" className="patho-img explainations-img-purple" />
          </div>
          <div className="d-flex flex-column patho-explanation-right">
            <h2 className="section-title h2-title patho-title">
              Bien-être global et bien-être sexuel : deux états intimement liés
            </h2>
            <Container className="patho-container">
              <div className="patho-paragraph">
                <p>
                  Depuis quelques années, nous savons que le bien-être sexuel a une forte
                  incidence sur le bien-être global d’une personne. Et que par conséquent,
                  les troubles sexuels peuvent être à l’origine de plusieurs difficultés
                  psychologiques.
                </p>
                <p>
                  Si la sexualité est encore trop largement victime des tabous qui l’entourent ou
                  des fausses croyances, la médecine sexuelle n’a cessé d’évoluer, démocratisant les
                  questions liées à l’intime, l’accès aux soins de qualité et à plus grande échelle,
                  la diffusion d’une meilleure éducation sexuelle.
                </p>
                <p>
                  Reconnue aujourd’hui comme un enjeu de santé publique, la sexualité se libère
                  progressivement des mythes autour de la performance et de la course à l’orgasme :
                  nous avons tous et toutes des attentes différentes, ainsi que des sexualités
                  différentes.
                </p>
                <p className="d-none d-lg-block">
                  Les sexologues sont là pour être à l’écoute, libérer la parole, rappeler les
                  fondements de l’intimité et la place de la sexualité dans nos vies.
                </p>
                <p className="d-none d-lg-block">
                  Si comme Mia.co, vous pensez que chacun a le droit de vivre sa sexualité comme
                  il ou elle lui plaît, et que le bien-être sexuel est à la portée de tous et
                  toutes, nos sexologues sont là pour vous guider.
                </p>
                <Collapse in={open} className=" d-lg-none">
                  <div id="example-collapse-text">
                    <p>
                      Les sexologues sont là pour être à l’écoute, libérer la parole, rappeler les
                      fondements de l’intimité et la place de la sexualité dans nos vies.
                    </p>
                    <p>
                      Si comme Mia.co, vous pensez que chacun a le droit de vivre sa sexualité comme
                      il ou elle lui plaît, et que le bien-être sexuel est à la portée de tous et
                      toutes, nos sexologues sont là pour vous guider.
                    </p>
                  </div>
                </Collapse>
                <div className="d-flex flex-column d-lg-none">
                  {open === false ? (
                    <span
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                      className="read-more align-self-end"
                    >
                      Lire la suite
                    </span>
                  ) : (
                    <span
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                      className="read-more align-self-end"
                    >
                      Voir moins
                    </span>
                  )}
                </div>
              </div>
            </Container>
            <div className="d-flex cta-div justify-content-center justify-content-lg-start">
              <StyledLink
                variant="orange"
                path="/consulter-epanouissement/"
                className=""
                margin
              >
                CONSULTER
              </StyledLink>
            </div>
          </div>
        </Section>
        <Section className="d-flex flex-column therapist-carousel-section">
          <div className="d-flex flex-column therapist-div-wrapper">
            <h2 className="h2-title text-white text-center">DES EXPERTES POUR VOUS ACCOMPAGNER</h2>
            <TherapistCarousel />
          </div>
        </Section>
        <TestimoniesSection testimonies={testimonies} path="/consulter-epanouissement" />
        <Section className="d-flex flex-column align-items-center faq-section">
          <Container className="d-flex flex-column align-items-center">
            <h3 className="section-title text-center">
              FAQ
            </h3>
            <TextSectionList sections={sections} />
          </Container>
        </Section>
      </div>
    </Layout>
  );
};

export default IndexPage;
